var app = {
    // Application Constructor
    initialize: function() {


        // add handlebars helper
        Handlebars.registerHelper('equal', function(lvalue, rvalue, options) {
            if (arguments.length < 3)
                throw new Error("Handlebars Helper equal needs 2 parameters");
            if( lvalue!=rvalue ) {
                return options.inverse(this);
            } else {
                return options.fn(this);
            }
        });

        $.getJSON( "cv.json", app.onJsonLoaded );

        // bind events
        window.addEventListener('orientationchange', this.forceRedraw);
      
    },
    onJsonLoaded : function  (data) {
        //console.log(data.cv);

        // profile 
      
        var profile = Handlebars.compile($("#profile-template").html());
        $("#profile").html(profile(data.cv));

        // experience        
        var experience = Handlebars.compile($("#experience-template").html());
        $("#experience").html(experience(data.cv));

        // skills        
        var skills = Handlebars.compile($("#skills-template").html());
        $("#skills").html(skills(data.cv));

        // education        
        var education = Handlebars.compile($("#education-template").html());
        $("#education").html(education(data.cv));

        // interests        
        var interests = Handlebars.compile($("#interests-template").html());
        $("#interests").html(interests(data.cv));

         // awards        
        var awards = Handlebars.compile($("#awards-template").html());
        $("#awards").html(awards(data.cv));

         // brands        
        var brands = Handlebars.compile($("#brands-template").html());
        $("#brands").html(brands(data.cv));

         // portfolio        
        var portfolio = Handlebars.compile($("#portfolio-template").html());
        $("#portfolio").after(portfolio(data.portfolio));

        //init img scrollers
        var options = {
                autoPlay : false,
                lazyLoad : true,
                navigation:true,
                paginationSpeed : 400,
                slideSpeed : 300,
                singleItem : true,
                navigationText: ['<i class="fa fa-arrow-left"></i>','<i class="fa fa-arrow-right"></i>']

         
          };

        $('.owl-carousel').each(function() {
                $(this).owlCarousel(options);

        });

        // start revealing site elements
        app.showSite();
    },
    showSite: function  () {
        $("#name").velocity({

                opacity:1

            },{

                delay:200
            });
        $("#portfolio").velocity({

                opacity:1

            },{

                delay:200
            });
        $("#contact").velocity({

                opacity:1

            },{

                delay:400
            });
        
        var count = 4;
        $('#content').children('.section').each(function() {
            var delay = count * 200;
            if( delay > 1500 ) delay = 1500;

            $(this).velocity({

                opacity:1

            },{

                delay:delay

            });
            count++;
        });

        // check if browser is outdated and show msg
        outdatedBrowser({
            bgColor: '#F2B635',
            color: '#ffffff',
            lowerThan: 'IE10',
            languagePath: ''
        });
    },
    forceRedraw: function (){
        // use on orientation change, for android webbrowser en ios to reflow everything
        var element = document.getElementById('content');
        var disp = element.style.display;
        element.style.display = 'none';
        var trick = element.offsetHeight;
        element.style.display = disp;
    }
};